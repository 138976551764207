import { useState, useEffect } from 'react'
import { MapContainer, Marker, TileLayer, Tooltip, useMapEvents, Popup } from "react-leaflet";
import L, { MarkerCluster } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import "leaflet/dist/leaflet.css";
import axios from 'axios';

const Draggable = () => {
  const [markerData, setMarkerData] = useState();
  const [newMarker, setNewMarker] = useState(true);
  const [postalCode, setPostalCode] = useState(0);

 
  useEffect(() => {
    axios.get("https://geomapapi.acgindia.co.in/getData").then((res) => {
      setMarkerData(res.data.data);
    })
  }, [newMarker])


  const customIcon = new L.Icon({
    iconUrl: require("../../location.svg").default,
    iconSize: new L.Point(40, 47)
  });


  // eslint-disable-next-line
  const createClusterCustomIcon = function (cluster) {
    var sum = 0;
    // console.log("Clusters", cluster.getAllChildMarkers(), cluster)
    var allData = cluster.getAllChildMarkers();
    allData.map((item) => {
      return (sum = sum + item.options.data.val)
    })
    return L.divIcon({
      html: `<span>${sum}</span>`,
      className: "custom-marker-cluster",
      iconSize: L.point("auto", "auto", true),
    });
  };


  const handleChange = (val, e, changeId) => {
    let src_zip;
    let des_lat;
    let des_lng;
    let des_zip;
    axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${e.target.getLatLng().lat},${e.target.getLatLng().lng}&key=AIzaSyAcAmgKblczQ77XFX5lSkYlh-qhEkjIDxk`)
    .then((res)=>{
       var checkDatas = res.data.results.map((address_comp)=>{
      return address_comp.address_components.filter((long_nam)=>{
        if(long_nam.types[0]==="postal_code"){
          return long_nam.long_name
        }
      })
    })
    var checkDatas2 = checkDatas.filter((item)=>{
      if(item.length>0){
        return item[0].long_name
      }
    })
    src_zip = val.zipcode;
    des_lat = e.target.getLatLng().lat;
    des_lng = e.target.getLatLng().lng;
    des_zip = checkDatas2[0][0].long_name;
    // console.log("Src_zip:",src_zip+" | Des_lat:"+des_lat+" | Des_lng:"+des_lng+" | Des_zip:"+des_zip)
    var config = {
      method: 'put',
      url: 'https://geomapapi.acgindia.co.in/updateDestination',
      data : {
        source_zip: src_zip,
        destination_zip: des_zip,
        destination_lat: des_lat,
        destination_lng: des_lng,
      }
    };
    axios(config)
    .then((res) => {
      // console.log(res)
      setMarkerData(res.data.data.markers);
      // console.log("******************************************",res.data.data.markers)
    })
  })
  }

  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "80%", maxHeight: "100vh" }}>
        <MapContainer
          style={{ height: "100vh" }}
          
          center={[37.0902, -95.7129]}
          zoom={5}
          scrollWheelZoom={true}
        >
          <TileLayer
            
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MarkerClusterGroup
            // onClick={(e) => handleGetLocationOfCluster(e)}
            iconCreateFunction={createClusterCustomIcon}
            maxClusterRadius={150}
            spiderfyOnMaxZoom={true}
            polygonOptions={{
              fillColor: "#ffffff",
              color: "#f00800",
              weight: 5,
              opacity: 1,
              fillOpacity: 0.8,
            }}
            showCoverageOnHover={true}
            draggable={true}
          >
            {/* {console.log(markerData)} */}
            {markerData !== undefined && markerData.map((item) => {
              return (
                <Marker
                  key={item.id}
                  
                  data={item}
                  position={[item.lat, item.lng]}
                  
                  icon={customIcon}
                  draggable={true}
                  eventHandlers={{
                    dragend(e) {
                      handleChange(e.target.options.data, e, item.id)
                    },
                  }}
                >
                  <Tooltip>
                    {/* {item.name + " | " + item.val + " | ZipCode: " + item.zipcode + " | " + item.lat + ", " + item.lng} */}
                    {item.name + " | " + item.val}
                  </Tooltip>
                </Marker>
              )
            })}
          </MarkerClusterGroup>
        </MapContainer>
      </div>
      <div style={{ width: "20%", maxHeight: "100vh", overflowY: "scroll", border: "2px solid black", borderRadius: "5px" }}>
        {markerData && markerData?.map((markers, i) => {
          return (<>
            <div className='markerList'>
{/* public/logo */}
            <div style={{ display: "flex", paddingLeft:"10px" }}>
              <div style={{ fontWeight: "bold", width: "50px" }}>Name:</div> {markers.name}
            </div>
            <div style={{ display: "flex", paddingLeft:"10px" }}>
              <div style={{ fontWeight: "bold", width: "50px" }}>Val:</div> {markers.val}
            </div>
            <div style={{ display: "flex", paddingLeft:"10px" }}>
              <div style={{ fontWeight: "bold", width: "50px" }}>City:</div> {markers.city}
            </div>
            <div style={{ display: "flex", paddingLeft:"10px" }}>
              <div style={{ fontWeight: "bold", width: "50px" }}>Zip:</div> {markers.zipcode}
            </div>
            </div>
          </>)
        })}
      </div>
    </div>
  );
}

export default Draggable;
