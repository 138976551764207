import { useState, useEffect } from 'react'
import { MapContainer, Marker, TileLayer, Tooltip, useMapEvents, Popup } from "react-leaflet";
import L, { MarkerCluster } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import "leaflet/dist/leaflet.css";
import axios from 'axios';

const Undraggable = () => {
  const [markerData, setMarkerData] = useState();
  const [newMarker, setNewMarker] = useState(true);
  // const [srcId, setSrcId] = useState(0);
  const [srcData, setSrcData] = useState();
  // const [desId, setDesId] = useState(0);

  useEffect(() => {
    axios.get("https://geomapapi.acgindia.co.in/getData_notDrag")
      // axios.get("http://localhost:3005/markers")
      .then((res) => {
        setMarkerData(res.data.data);
        // console.log(res.data.data);
      })
  }, [newMarker])

  const customIcon = new L.Icon({
    iconUrl: require("../../location.svg").default,
    iconSize: new L.Point(40, 47)
  });

  const handleChange = (val) => {
    setSrcData(val.target.options.data);
    document.getElementById("modalBtn")?.click()
  }
  const handleSum = (val) => {
    // console.log("Value sum", val);
    axios.put("https://geomapapi.acgindia.co.in/swipeValue", {
      source_id: srcData?.id,
      destination_id: val,
    })
      .then((res) => {
        document.getElementById("closeModal")?.click()
        setMarkerData(res.data.data.markers);
        var updatedSrc = res.data.data.markers.filter((item) => {
          return item.id == val
        });
        setSrcData(updatedSrc[0])
      })
  }

  // eslint-disable-next-line
  const createClusterCustomIcon = function (cluster) {
    var sum = 0;
    var allData = cluster.getAllChildMarkers();
    allData.map((item) => {
      return (sum = sum + item.options.data.val)
    })
    return L.divIcon({
      html: `<span>${sum}</span>`,
      className: "custom-marker-cluster",
      iconSize: L.point("auto", "auto", true),
    });
  };


  
const uniqueArray = markerData && markerData.filter((value, index) => {
  const _value = JSON.stringify(value);
  return index === markerData.findIndex(obj => {
    // console.log("obj", obj.repCode,JSON.parse(_value).repCode)
    return (obj.repCode===JSON.parse(_value).repCode && obj.assignedRep===JSON.parse(_value).assignedRep);
    // return JSON.stringify(obj) === _value;
  });
});

// console.log("new",uniqueArray);

  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "80%", maxHeight: "100vh" }}>
        <MapContainer
          style={{ height: "100vh" }}
          
          center={[37.0902, -95.7129]}
          zoom={5}
          scrollWheelZoom={true}
        >
          <TileLayer
            
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MarkerClusterGroup
            iconCreateFunction={createClusterCustomIcon}
            maxClusterRadius={150}
            spiderfyOnMaxZoom={true}
            polygonOptions={{
              fillColor: "#ffffff",
              color: "#f00800",
              weight: 5,
              opacity: 1,
              fillOpacity: 0.8,
            }}
            showCoverageOnHover={true}
          // draggable={false}
          >
            {/* {console.log(markerData)} */}
            {markerData !== undefined && markerData.map((item) => {
              return (
                <Marker
                  key={item.id}
                  
                  data={item}
                  position={[item.lat, item.lng]}
                  
                  icon={customIcon}
                  // draggable={false}
                  eventHandlers={{
                    click(e) {
                      handleChange(e)
                    },
                  }}
                >
                  <Tooltip>
                    {/* {item.name + " | " + item.val + " | ZipCode: " + item.zipcode + " | " + item.lat + ", " + item.lng} */}
                    {item.accountName + " | " + item.val}
                  </Tooltip>
                </Marker>
              )
            })}
          </MarkerClusterGroup>
        </MapContainer>
      </div>
      <div className='dataList'>
        {markerData && markerData?.map((markers, i) => {
          return (<>
            <div className='dataListItems'>
              <div style={{ display: "flex", paddingLeft: "10px" }}>
                <div style={{ fontWeight: "bold", width: "150px" }}>Name:</div> {markers.accountName}
              </div>
              <div style={{ display: "flex", paddingLeft: "10px" }}>
                <div style={{ fontWeight: "bold", width: "150px" }}>Val:</div> {markers.val}
              </div>
              <div style={{ display: "flex", paddingLeft: "10px" }}>
                <div style={{ fontWeight: "bold", width: "150px" }}>City:</div> {markers.city}
              </div>
              <div style={{ display: "flex", paddingLeft: "10px" }}>
                <div style={{ fontWeight: "bold", width: "150px" }}>Account Name:</div> {markers.accountName}
              </div>
              <div style={{ display: "flex", paddingLeft: "10px" }}>
                <div style={{ fontWeight: "bold", width: "150px" }}>Ori. Assignment:</div> {markers.originalAssignment}
              </div>
              <div style={{ display: "flex", paddingLeft: "10px" }}>
                <div style={{ fontWeight: "bold", width: "150px" }}>Predictive Rank:</div> {markers.predictiveRank}
              </div>
              <div style={{ display: "flex", paddingLeft: "10px" }}>
                <div style={{ fontWeight: "bold", width: "150px" }}>PY Spend:</div> {markers.pySpend}
              </div>
              <div style={{ display: "flex", paddingLeft: "10px" }}>
                <div style={{ fontWeight: "bold", width: "150px" }}>Predictive Spend:</div> {markers.predictiveSpend}
              </div>
              <div style={{ display: "flex", paddingLeft: "10px" }}>
                <div style={{ fontWeight: "bold", width: "150px" }}>Account:</div> {markers.account}
              </div>
              <div style={{ display: "flex", paddingLeft: "10px" }}>
                <div style={{ fontWeight: "bold", width: "150px" }}>Rep Code:</div> {markers.repCode}
              </div>
              <div style={{ display: "flex", paddingLeft: "10px" }}>
                <div style={{ fontWeight: "bold", width: "150px" }}>Assigned Rep:</div> {markers.assignedRep}
              </div>
              <div style={{ display: "flex", paddingLeft: "10px" }}>
                <div style={{ fontWeight: "bold", width: "150px" }}>S3 Sub Region:</div> {markers.s3SubRegion}
              </div>
              <div style={{ display: "flex", paddingLeft: "10px" }}>
                <div style={{ fontWeight: "bold", width: "150px" }}>S2 Region:</div> {markers.s2Region}
              </div>
              <div style={{ display: "flex", paddingLeft: "10px" }}>
                <div style={{ fontWeight: "bold", width: "150px" }}>S1 Geo:</div> {markers.s1Geo}
              </div>
            </div>
          </>)
        })}
      </div>
      {/* <!-- Button trigger modal --> */}
      <button style={{ display: "none" }} type="button" id="modalBtn" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
        Launch demo modal
      </button>

      {/* <!-- Modal --> */}
      <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">All Users</h5>
              <button type="button" id="closeModal" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div style={{ borderBottom: "2px solid black", padding: "5px 10px" }}>
              <h5>Selected Data:</h5>
              <div style={{ display: "flex" }}>
                {markerData && markerData.filter((item) => {
                  if (item.id == srcData?.id) {
                    return item
                  }
                }).map((markers, i) => {
                  return (
                    <>
                      <div style={{ fontWeight: "bold", width: "130px" }}>Assigned Rep:</div><div style={{ width: "170px" }}>{markers?.assignedRep}</div>
                      <div style={{ fontWeight: "bold", width: "80px" }}>Rep Code:</div><div style={{ width: "50px" }}>{markers?.repCode}</div>
                    </>
                  )
                })}
              </div>
            </div>
            <div className="modal-body">
              {/* {console.log("srcData", srcData?.accountName, srcData?.val)} */}
              {uniqueArray && uniqueArray.filter((markers) => {
                if (markers.id != srcData?.id) {
                  return markers
                }
              }).map((markers, i) => {
                return (
                  <>
                    <div className="user" onClick={() => { handleSum(markers.id) }} key={i}>
                      <div style={{ fontWeight: "bold", width: "130px" }}>Assigned Rep: </div><div style={{ width: "170px" }}>{markers.assignedRep}</div>
                      <div style={{ fontWeight: "bold", width: "100px" }}>Rep Code: </div><div style={{ width: "50px" }}>{markers.repCode}</div>
                    </div>
                  </>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Undraggable;
