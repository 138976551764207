import React from 'react'
import Home from './Components/Home/Home'
import DraggableMap from './Components/DraggableMap/Draggable'
import UndraggableMap from './Components/UndraggableMap/Undraggable'
import { Route, Switch } from 'react-router-dom'
import './App.css'

const App = () => {
  return (
    <>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/draggable-map" component={DraggableMap} />
          <Route exact path="/undraggable-map" component={UndraggableMap} />
        </Switch>
    </>
  )
}

export default App