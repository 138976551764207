import React from 'react'
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className='container'>
      <div className='title'>Select Map App</div>
      <div className='buttonPanel'>
        <Link to="/draggable-map"><button type="button" className='btn btn-outline-primary dragBtn' >Draggable Marker App</button></Link>
        <Link to="/undraggable-map"><button type="button" className='btn btn-outline-primary undragBtn'>Undraggable Marker App</button></Link>
      </div>
    </div>
  )
}

export default Home